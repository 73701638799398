import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../components/layout"


const Krovlya = ({ data }) => {
  return (
    <Layout>
      <div className="mt-8">
        <div className="bg-transparent">
          <h2
            className="text-2xl font-extrabold md:text-3xl lg:text-4xl xl:text-4xl"
            style={{
              fontFamily: `Montserrat`,
              color: `white`,
              padding: `1rem`,
            }}
          >
            РЕМОНТ КРОВЛИ
          </h2>
        </div>
      </div>
      <div className="mt-20">
        {data.allContentfulKrovlya.nodes.map(product => (
          <div key={product.id}>
            <div className="w-full flex flex-col mb-6 md:flex-row">
              <div className="flex justify-center items-center">
                <Img
                  fluid={product.image.fluid}
                  style={{
                    width: `20rem`,
                    height: `15rem`,
                  }}
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>
              <div className="p-6">
                {" "}
                <h3
                  className="text-xl md:text-2xl lg:text-2xl xl:text-2xl"
                  style={{
                    fontFamily: `Montserrat`,
                    color: `#F1F1F2`,
                  }}
                >
                  {product.title}
                </h3>
                <p
                  className="text-sm md:text-sm lg:text-sm xl:text-sm"
                  style={{
                    fontFamily: `Montserrat`,
                    color: `#F1F1F2`,
                  }}
                >
                  {documentToReactComponents(product.description.json)}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default Krovlya

export const PageQuery = graphql`
  query Krovlya {
    allContentfulKrovlya {
      nodes {
        description {
          json
        }
        title
        image {
          fluid(maxWidth: 600, quality: 35) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
